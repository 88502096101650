<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Control de Ejecuciones</v-card-title>

      <v-card-text>
        <!-- <div class="text-center" v-if="loadingTiposOperacion">
          <div class="spinner-border" role="status"></div>
        </div> -->
        <!-- <filters
          v-if="!loadingTiposOperacion"
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters> -->

        <br /><br /><br />
        <div class="text-center" v-if="loadingArchivos">
          <div class="spinner-border" role="status"></div>
        </div>

        <strong
          >Ejecuciones de Validación de Archivos:
          {{ archivosPendientes ? archivosPendientes.length : "" }}</strong
        >
        <CDataTable
          v-if="!loadingArchivos"
          hover
          striped
          :items="archivosPendientes"
          :fields="fieldsEjecucionesGenerarVariables"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
        </CDataTable>

        <div class="text-center" v-if="loadingVariables">
          <div class="spinner-border" role="status"></div>
        </div>

        <strong
          >Ejecuciones de Generación de Variables:
          {{ variablesPendientes ? variablesPendientes.length : "" }}</strong
        >
        <CDataTable
          v-if="!loadingVariables"
          hover
          striped
          :items="variablesPendientes"
          :fields="fieldsEjecucionesGenerarVariables"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
        </CDataTable>

        <div class="text-center" v-if="loadingCalidad">
          <div class="spinner-border" role="status"></div>
        </div>

        <strong
          >Ejecuciones Procesos de Calidad:
          {{
            ejecucionesCalidadPendientes
              ? ejecucionesCalidadPendientes.length
              : ""
          }}</strong
        >
        <CDataTable
          v-if="!loadingCalidad"
          hover
          striped
          :items="ejecucionesCalidadPendientes"
          :fields="fieldsEjecucionesCalidad"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
        </CDataTable>

        <div class="text-center" v-if="loadingConciliador">
          <div class="spinner-border" role="status"></div>
        </div>

        <strong
          >Ejecuciones Procesos de Conciliación:
          {{
            ejecucionesConciliadorPendientes
              ? ejecucionesConciliadorPendientes.length
              : ""
          }}</strong
        >
        <CDataTable
          v-if="!loadingConciliador"
          hover
          striped
          :items="ejecucionesConciliadorPendientes"
          :fields="fieldsEjecucionesConciliador"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
        </CDataTable>
      </v-card-text>
    </v-card>
    <br /><br />
  </div>
</template>

<script>
import Filters from "@/components/filters";
import EjecucionProcesoCalidadService from "@/services/ejecucionProcesoCalidad.service";
import EjecucionProcesoConciliadorService from "@/services/ejecucionProcesoConciliador.service";
import ArchivoService from "@/services/archivo.service";
import { mapState } from "vuex";

export default {
  name: "Users",
  data() {
    return {
      items: [],
      fieldsEjecucionesGenerarVariables: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "fecha_inicio", label: "Lanzamiento" },
      ],
      fieldsEjecucionesCalidad: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "fecha_inicio", label: "Lanzamiento" },
      ],
      fieldsEjecucionesConciliador: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "fecha_inicio", label: "Lanzamiento" },
      ],
      filterFields: [
        { value: "id", label: "Código" },
        { value: "nombre", label: "Nombre" },
        {
          value: "tipo_operacion_id",
          label: "Tipo de Operación",
          //TODO: Traer desde Base de Datos!
          options: [
            { id: 61, name: "Asociada Tercerizada" },
            { id: 62, name: "Asociada Operada" },
            { id: 63, name: "Tercerizada" },
          ],
        },
        {
          value: "activo",
          label: "Estado",
          options: [
            { id: 1, name: "Habilitado" },
            { id: 0, name: "Inhabilitado" },
          ],
        },
      ],
      activePage: 1,
      loadingArchivos: true,
      loadingVariables: true,
      loadingCalidad: true,
      loadingConciliador: true,
      archivosPendientes: undefined,
      variablesPendientes: undefined,
      ejecucionesCalidadPendientes: undefined,
      ejecucionesConciliadorPendientes: undefined,
      alert: false,
      message: "",
    };
  },
  components: {
    Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    //if (!this.permissions.includes("Definición de proceso de calidad"))
    //  this.$router.push("/dashboard");

    this.fetchArchivosPendientes();
    this.fetchVariablesPendientes();
    this.fetchEjecucionesPendientesProcesosCalidad();
    this.fetchEjecucionesPendientesProcesosConciliador();
  },
  methods: {
    fetchEjecucionesPendientesProcesosCalidad() {
      EjecucionProcesoCalidadService.getEjecucionesPendientes().then(
        (response) => {
          this.ejecucionesCalidadPendientes = response.data;
          this.ejecucionesCalidadPendientes =
            this.ejecucionesCalidadPendientes.map(function f(x) {
              return { ...x, nombre: x.proceso_calidad.nombre };
            });
          console.log("Ejecuciones Calidad en curso...");
          console.log(response.data);
          this.loadingCalidad = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCalidad = false;
        }
      );
    },

    fetchEjecucionesPendientesProcesosConciliador() {
      EjecucionProcesoConciliadorService.getEjecucionesPendientes().then(
        (response) => {
          this.ejecucionesConciliadorPendientes = response.data;
          this.ejecucionesConciliadorPendientes =
            this.ejecucionesConciliadorPendientes.map(function f(x) {
              return { ...x, nombre: x.proceso_conciliador.nombre };
            });
          console.log("Ejecuciones Conciliador en curso...");
          console.log(response.data);
          this.loadingConciliador = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingConciliador = false;
        }
      );
    },

    fetchArchivosPendientes() {
      ArchivoService.getArchivosPendientes().then(
        (response) => {
          this.archivosPendientes = response.data;
          console.log("arvhivos en curso...");
          console.log(response.data);
          this.loadingArchivos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingArchivos = false;
        }
      );
    },

    fetchVariablesPendientes() {
      ArchivoService.getVariablesPendientes().then(
        (response) => {
          this.variablesPendientes = response.data;
          console.log("arvhivos en curso...");
          console.log(response.data);
          this.loadingVariables = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingVariables = false;
        }
      );
    },

    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
